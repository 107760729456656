import React, {useState, useEffect, useRef} from "react";

import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import {useTranslation, Trans} from "react-i18next";

import QRCode from 'react-qr-code';

import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import TelegramIcon from '@mui/icons-material/Telegram';

import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';


import "../../scss/ShareScreen.scss"

import {DEFAULT_TASK_EXPIRATION, DEFAULT_TASK_EXPIRATION_IN_DAYS} from "../constants";

import {isMobile} from 'react-device-detect';
import copy from 'copy-to-clipboard';
import backendService from "../BackendService";
import {formatDateString} from "../GlobalUtils";


const ShareScreen = ({playroomId, ...props}) => {
    const [hwEnabled, setHwEnabled] = useState(false);
    const instructions = useRef();
    const [prTitle, setPrTitle] = useState("");
    const [hwInstructions, setHwInstructions] = useState("");

    const shareUrl = `${window.location.protocol}//${window.location.hostname}/${playroomId}`;
    const [expMs, setExpMs] = useState(0);
    const [exp, setExp] = useState({});
    const { t } = useTranslation(); // שימוש בפונקציית התרגום מתוך i18n

    const [openDate, setOpenDate] = useState(false);

    const [showLink, setShowLink] = useState(false);
    const [msg, setMsg] = useState("fd");

    const close = e => {
        window.history.back();
    }
    const closeLink = e => {
        setShowLink(false)
    }

    const closeAction = (
        <>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={closeLink}
                style={{flex: "1 0 auto", justifyContent: "end"}}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </>
    );


    const sendLink = async e => {
        let fallback = true;

        if(isMobile && navigator.share !== undefined){
            try {
                await navigator.share({ url: shareUrl });
            } catch (e) {
                if(!e.message.includes("canceled")){
                    fallback = true;
                }
            }
        } else {
            fallback = true;
        }

        if(fallback){
            copy(shareUrl);
            setMsg("הקישור הועתק")
            setShowLink(true)
        }


        if(props.onShare){
            props.onShare();
        }
    }
    const handleInstructionsClick = e => {
        backendService.updatePlayroom({
            hwExpiration: e.target.checked ? new Date().getTime() + DEFAULT_TASK_EXPIRATION : null
        })
        setHwEnabled(e.target.checked);
        setExpMs(new Date().getTime() + DEFAULT_TASK_EXPIRATION);

        if(e.target.checked){
            setTimeout(() => {
                document.getElementById("hw_instructions").focus();
            }, 100)

        }

    }
    const onInputDown = e => {
        if (e.key === 'Enter') {
            e.target.blur(); // גורם לשדה הטקסט לאבד פוקוס
        }
    }
    const onFocus = e => {
        e.target.select()
    }
    const editTime = e => {
        // TBD
    }
    const editDate = e => {
        // TBD
    }
    const onTitleBlur = e => {
        backendService.updatePlayroom({
            title: e.target.value.trim() || null
        })
    }
    const onInstructionsBlur = e => {
        backendService.updatePlayroom({
            hwInstructions: encodeURIComponent(e.target.value.trim()) || null
        })
    }

    useEffect(() => {
        setExp(formatDateString(expMs));
    }, [expMs])

    useEffect(() => {
        console.log("USE EFFECT");
        let prConfig = backendService.getPlayroomConfig();
        console.log(prConfig);

        instructions.current.value = decodeURIComponent(prConfig.hwInstructions || "")
        setHwInstructions(decodeURIComponent(prConfig.hwInstructions || ""));
        setHwEnabled(!!prConfig.hwExpiration && prConfig.hwExpiration > new Date().getTime());

        setPrTitle(prConfig.title || "");

        setExpMs(prConfig.hwExpiration || (new Date().getTime() + DEFAULT_TASK_EXPIRATION));

        // TODO register to backendService "playroom_state" and listen for config changes

    }, [])

    return <div className={"share_screen dir"}>
        <IconButton className={"close"} onClick={close}>
            <CloseIcon></CloseIcon>
        </IconButton>
        <h2 style={{marginTop: 0}}>{t("invite")}</h2>
        <Box className="title" sx={{ display: 'flex', alignItems: 'flex-end', width: "100%" }}>
            <EditIcon className="edit_icon" sx={{ color: 'action.active', my: 1.7 }} />
            <TextField fullWidth
                       value={prTitle}
                       onChange={e => setPrTitle(e.target.value)}
                       className="dir align"
                       style={{left: "auto", right: "0", flex: "1 1 auto"}} id="input-with-sx" label={t("playroom_name")} variant="standard"
                       onKeyDown={onInputDown}
                       onFocus={onFocus}
                       onBlur={onTitleBlur}
                       onContextMenu={e => e.preventDefault()}
                        />
        </Box>
        <QRCode className="qr" value={shareUrl} />
        <Box className="dir align">
            <Switch checked={hwEnabled}
                    onClick={handleInstructionsClick}
                    onChange={e => setHwEnabled(!hwEnabled)} />
            {t("open_for_hw")}
            <HelpOutlineIcon style={{position: "relative",
                top: "5px",
                margin: "0 10px", height: "20px"}}
                onClick={e => alert(t("home_task_description", {days: DEFAULT_TASK_EXPIRATION_IN_DAYS}))}/>
        </Box>

        <TextField
            id="hw_instructions"
            ref={instructions}
            fullWidth
            disabled={!hwEnabled}
            className="dir align instructions"
            inputProps={{dir: "rtl"}}
            label={t("instructions")}
            multiline
            value={hwInstructions}
            onChange={e => setHwInstructions(e.target.value)}
            onFocus={onFocus}
            onBlur={onInstructionsBlur}
            onContextMenu={e => e.preventDefault()}
            rows={4}
        />
        <Box id="hw_expiration" hidden={!hwEnabled}>
            <Trans
            i18nKey="task_open_until"
            values={{day: exp.day, month: exp.month, year: exp.year, hours: exp.hours, minutes: exp.minutes}}
            /*components={{
                editTime: <a onClick={editTime}/>,
                editDate: <a onClick={editDate}/>
            }}*/
            />
        </Box>
        <div id="bottom_bar" className="align-opp">
            <Button variant="contained" endIcon={<TelegramIcon />} onClick={sendLink}>{t("send_link")}</Button>
        </div>

        <Snackbar
            open={showLink}
            className="dir"
            autoHideDuration={5000}
            TransitionComponent={Slide}
            onClose={closeLink}
            message={msg}
            action={closeAction}
        />

    </div>
}

export default React.memo(ShareScreen);