import {MathUtils as maath} from "three";
import {geometry} from "maath";
import * as THREE from "three";
import i18n from "../../i18n";
import {DEFAULT_BLUE} from "../../constants";

const flipGeometryUVs = (geometry) => {
    geometry.attributes.uv.array.forEach((_, index) => {
        if (index % 2 === 0) {
            geometry.attributes.uv.array[index] = 1 - geometry.attributes.uv.array[index];
        }
    });
    geometry.attributes.uv.needsUpdate = true;
};


export const SIZE_FACTOR = 10;
export const Card = {
    width: 108 * SIZE_FACTOR,
    height: 156 * SIZE_FACTOR,
    aspectRatio: 108/156,
    radius: 5 * SIZE_FACTOR,
    outlineWidth: 0.7 * SIZE_FACTOR,
    envTextureURLs: [
        `${process.env.PUBLIC_URL}/img/texture/px.jpg`,
        `${process.env.PUBLIC_URL}/img/texture/nx.jpg`,
        `${process.env.PUBLIC_URL}/img/texture/py.jpg`,
        `${process.env.PUBLIC_URL}/img/texture/ny.jpg`,
        `${process.env.PUBLIC_URL}/img/texture/pz.jpg`,
        `${process.env.PUBLIC_URL}/img/texture/nz.jpg`
    ],
    pivotOffsetX: 5 * SIZE_FACTOR,
    maxAngleDeltaDeg: 60,
    minVerticalDrag: 4 * SIZE_FACTOR,
    verticalDragVelocity: 5
}
Card.maxAngleDeltaRad = maath.degToRad(Card.maxAngleDeltaDeg);


export const CardFrontGeometry = new geometry.RoundedPlaneGeometry(Card.width, Card.height, Card.radius)
CardFrontGeometry.computeVertexNormals()

export const CardOverlayGeometry = new geometry.RoundedPlaneGeometry(Card.width * 0.3, Card.width * 0.3, Card.width * 0.15);
CardOverlayGeometry.computeVertexNormals();

export const CardOverlayMaterial = new THREE.MeshLambertMaterial({
    color: "#FFFFFF",
    side: THREE.FrontSide,
    map: new THREE.TextureLoader().load(`${process.env.PUBLIC_URL}/img/icons/check_icon.jpg`)
})

export const CardOutlineGeometry = new geometry.RoundedPlaneGeometry(
    Card.width +  Card.outlineWidth,
    Card.height + Card.outlineWidth,
    Card.radius + Card.outlineWidth)
export const CardOutlineMaterial = new THREE.MeshBasicMaterial({color: 0xAAAAAA, side: THREE.FrontSide})

export const CardBackGeometry = new geometry.RoundedPlaneGeometry(Card.width, Card.height, Card.radius)
CardBackGeometry.computeVertexNormals()
flipGeometryUVs(CardBackGeometry);

export const SELECTION_PANEL_PLACEMENTS = 3;
export const DEFAULT_PLACEMENT_CONFIG = {size: 1};
export const CARD_MOVEMENT_DAMP = 0;
export const SHADOW_MAP_SIZE = 2048;
export const PLACEHOLDER_SCALE = 1.05;

export const PAGING_DEFAULT_CAMERA_POS = new THREE.Vector3(0, -120 * SIZE_FACTOR, 330 * SIZE_FACTOR);
export const PAGING_DEFAULT_CAMERA_POS_VIEW_ONLY = new THREE.Vector3(0, -90 * SIZE_FACTOR, 295 * SIZE_FACTOR);
export const PAGING_DEFAULT_CAMERA_TARGET = new THREE.Vector3(0,30 * SIZE_FACTOR, 0);
export const PAGING_DEFAULT_CAMERA_TARGET_VIEW_ONLY = new THREE.Vector3(0, 0, 0);
export const PAGING_MAX_CAMERA_DISTANCE_VIEW_ONLY = 353 * SIZE_FACTOR;
export const PAGING_MAX_CAMERA_DISTANCE = 353 * SIZE_FACTOR;
export const PAGING_MIN_CAMERA_DISTANCE_VIEW_ONLY = 100 * SIZE_FACTOR;
export const PAGING_MIN_CAMERA_DISTANCE = 230 * SIZE_FACTOR;
export const PAGING_FRUSTUM_FAR = 500 * SIZE_FACTOR;

export const GALLERY_MAX_CAMERA_DISTANCE = 630 * SIZE_FACTOR;
export const GALLERY_FRUSTUM_FAR = GALLERY_MAX_CAMERA_DISTANCE * 1.1;

export const GALLERY_COLUMNS = 3;
export const GALLERY_GAP_FACTOR = 0.1;
export const GALLERY_EFFECTIVE_CARD_WIDTH = Card.width * (1 + GALLERY_GAP_FACTOR);
export const GALLERY_EFFECTIVE_CARD_HEIGHT = Card.height * (1 + GALLERY_GAP_FACTOR);
export const ARRANGEMENT_TRANSITION_DURATION_SEC = 1;