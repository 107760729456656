import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react'
import "../../../css/Playroom.css";
import * as THREE from "three";
import {CubeTextureLoader, MathUtils as maath, TextureLoader} from "three";
import * as PlayroomConstants from "./PlayroomConstants";
import {
    ARRANGEMENT_TRANSITION_DURATION_SEC,
    Card,
    CARD_MOVEMENT_DAMP,
    GALLERY_COLUMNS,
    GALLERY_EFFECTIVE_CARD_HEIGHT,
    GALLERY_EFFECTIVE_CARD_WIDTH,
    GALLERY_FRUSTUM_FAR,
    GALLERY_MAX_CAMERA_DISTANCE,
    PAGING_DEFAULT_CAMERA_POS, PAGING_DEFAULT_CAMERA_POS_VIEW_ONLY,
    PAGING_DEFAULT_CAMERA_TARGET, PAGING_DEFAULT_CAMERA_TARGET_VIEW_ONLY,
    PAGING_FRUSTUM_FAR,
    PAGING_MAX_CAMERA_DISTANCE,
    PAGING_MIN_CAMERA_DISTANCE,
    PLACEHOLDER_SCALE,
    SELECTION_PANEL_PLACEMENTS,
    SHADOW_MAP_SIZE,
    SIZE_FACTOR
} from "./PlayroomConstants";
import {damp} from "maath/easing";
import {CardNative} from "./CardNative";
import {useInteraction3D} from "./Interaction3DUtils";
import {OrbitControls} from "three/addons";
import {PLACED_CARD_SCALE, SelectionPanel} from "./SelectionPanel";
import backendService from "../../BackendService";
import {get2DPositionFrom3D, get3DPositionFrom2D} from "./Global3dUtils";
import i18n from '../../i18n';
import {useTranslation} from "react-i18next";
import {createLabelCanvas} from "./PlayroomUtils";
import frameRateOptimizer, {PIXEL_RATIO_UPDATE} from "./utils/FrameRateOptimizer";
import gsap from 'gsap'
import {removeElementFromArray} from "../../GlobalUtils";

let mainEnv;

// TODO enable shadows on high end computers, this can be decided by platform type or by frame rate
const ENABLE_SHADOWS = (new URLSearchParams(window.location.search).get("shadows") === "true");

const canvas = document.createElement("canvas");
canvas.style.filter = "saturate(1.6) brightness(1.2) contrast(1.2)";

// IMPORTANT: currently we use Three.js r162 for compatibility, as this is the last version to support WebGL1. Safari on Mac doesn't support webGL2, and this is a major problem
let gl = canvas.getContext("webgl2") || canvas.getContext("webgl") || canvas.getContext('experimental-webgl');

if(gl){
    gl.enable(gl.DEPTH_TEST);
    gl.depthFunc(gl.LESS);
    //gl.toneMapping = THREE.ACESFilmicToneMapping;
    gl.toneMapping = THREE.ReinhardToneMapping;
    gl.outputEncoding = THREE.SRGBColorSpace;
    gl.outputColorSpace = THREE.SRGBColorSpace;
    canvas.addEventListener("webglcontextlost", e => {
        e.preventDefault();
    }, false);
} else {
    alert("WebGL not supported");
}

const renderer = new THREE.WebGLRenderer({
    antialias: true,
    canvas: canvas,
    context: gl
});

let effectivePixelRatio = window.devicePixelRatio;
const FPSOptimizer = frameRateOptimizer;
FPSOptimizer.on(PIXEL_RATIO_UPDATE, newPR => {
    effectivePixelRatio = newPR;
    renderer.setPixelRatio(effectivePixelRatio);
})

renderer.setPixelRatio(effectivePixelRatio);
renderer.setSize(window.innerWidth, window.innerHeight);
if(ENABLE_SHADOWS){
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;
}

const scene = new THREE.Scene();
const camera = new THREE.PerspectiveCamera(50, window.innerWidth / (window.innerHeight), 120 * SIZE_FACTOR, PAGING_FRUSTUM_FAR);


camera.position.copy(PAGING_DEFAULT_CAMERA_POS);

const controls = new OrbitControls( camera, renderer.domElement );

camera.lookAt(PAGING_DEFAULT_CAMERA_TARGET)

controls.enableRotate = false;
controls.enablePan = false;
controls.zoomSpeed = 1;
controls.zoomToCursor = false;
controls.maxDistance = PAGING_MAX_CAMERA_DISTANCE;
controls.minDistance = PAGING_MIN_CAMERA_DISTANCE;
controls.enableDamping = true;
controls.dampingFactor = 0.08;
controls.panSpeed = 1.5;

controls.target.copy(PAGING_DEFAULT_CAMERA_TARGET);

function setupShadowForLight(light, alpha){
    light.castShadow = true;
    //light.shadowDarkness = alpha;   // deprecated
    light.shadow.camera.near = 1200;
    light.shadow.camera.far = 5000;
    light.shadow.radius = 30;
    light.shadow.mapSize = new THREE.Vector2(SHADOW_MAP_SIZE, SHADOW_MAP_SIZE);

    let d = 50;

    light.shadow.camera.left = -d;
    light.shadow.camera.right = d;
    light.shadow.camera.top = d;
    light.shadow.camera.bottom = -d;
    light.shadow.camera.radius = 1;
    light.shadow.camera.bias = -0.004;
}

// LIGHTING
let ambientLight = new THREE.AmbientLight("#ffeeb1", 0.4);
scene.add(ambientLight)

let pointLight1 = new THREE.PointLight("white", ENABLE_SHADOWS ? 1 : 1, 0, 0);
pointLight1.position.set(-Card.width, 0, 380 * SIZE_FACTOR);
scene.add(pointLight1);

let pointLight2 = new THREE.PointLight("white", ENABLE_SHADOWS ? 1 : 1, 0, 0);
pointLight2.position.set(Card.width, 0, 380 * SIZE_FACTOR);
scene.add(pointLight2);


if(ENABLE_SHADOWS){
    let pointLight3 = new THREE.PointLight("white", 0.7, 600 * SIZE_FACTOR, 0);
    pointLight3.position.set(Card.width * 0.1, Card.height * 0.7, 150 * SIZE_FACTOR);
    scene.add(pointLight3);
    setupShadowForLight(pointLight1);
    setupShadowForLight(pointLight2);
    //setupShadowForLight(pointLight3);
}


// TABLE
const TABLE_HEIGHT = 7000;
let table = new THREE.Mesh(new THREE.PlaneGeometry(2000 * SIZE_FACTOR, TABLE_HEIGHT * SIZE_FACTOR), new THREE.MeshLambertMaterial({
    color: "#BBB",
    map: new TextureLoader().load(`${process.env.PUBLIC_URL}/img/texture/cloth_2.jpg`)
}))
table.meshType = "tableMesh";
table.receiveShadow = true;


table.material.map.repeat.set(4*8, 4*20);
table.material.map.wrapS = THREE.RepeatWrapping;
table.material.map.wrapT = THREE.RepeatWrapping;
table.position.z = -2 * SIZE_FACTOR;
table.position.y = -Card.height * TABLE_HEIGHT / 444.4444444;
scene.add(table);

const shadow = new THREE.Mesh(new THREE.PlaneGeometry(Card.width, Card.height), new THREE.MeshBasicMaterial({
    transparent: true,
    map: new TextureLoader().load(`${process.env.PUBLIC_URL}/img/cardShadow.png`)
}));

shadow.position.z = -1 * SIZE_FACTOR;
shadow.visible = false;
scene.add(shadow);



let topCard = null;
let allCardsInDeck = [];
let cardMap = {};
let plainSelections = [];

let pp = {y: 0};
let pagingTarget = 0;

let placementDragTarget = {x: 0, y: 0};

const MONITOR_MEMORY = false;
let lastHeap = 0;
if(MONITOR_MEMORY){
    setInterval(() => {
        let mem = performance.memory.usedJSHeapSize;
        console.log(mem - lastHeap);
        lastHeap = mem;
    }, 2000)
}

const phCardMat = new THREE.MeshLambertMaterial({
    color: "white",
    transparent: true,
    opacity: 0.2,
    map: createLabelCanvas(i18n.t("card_needed"), false)
})
const phCard = new THREE.Mesh(PlayroomConstants.CardFrontGeometry, phCardMat);
phCard.position.z = -SIZE_FACTOR;
phCard.scale.set(PLACEHOLDER_SCALE, PLACEHOLDER_SCALE, PLACEHOLDER_SCALE);
scene.add(phCard);

const fitSize = () => {
    let menuBar = document.getElementById("menu_bar");
    let mbHeight = 0;
    if(menuBar){
        mbHeight = menuBar.clientHeight;
    }
    const width = window.innerWidth;
    const height = window.innerHeight - mbHeight;

    // אם יש שינוי בגודל, עדכון הגודל של ה-renderer
    const needResize = canvas.width !== ~~(width * effectivePixelRatio) || canvas.height !== ~~(height * effectivePixelRatio);
    if (needResize) {
        //console.log("RESIZE: ", canvas.height, height, effectivePixelRatio);
        if(camera){
            camera.aspect = width / height;
            camera.updateProjectionMatrix();
        }
        renderer.setSize(width, height);
    }
}

const updateDeckShadow = () => {
    if (allCardsInDeck.length && arrangement === "paging") {

        shadow.visible = (allCardsInDeck.length > 1 || !isDragging);

        let layingCards = allCardsInDeck.filter(c => c.realAngleRad == 0 || c.index == 0);

        let placement = layingCards.reduce((p, c) => {
            p.rot += c.cardGroup.rotation.z;
            p.x += c.cardGroup.position.x - (-Card.width / 2 - Card.pivotOffsetX);
            p.y += c.cardGroup.position.y;
            return p;
        }, {x: 0, y: 0, rot: 0});

        if (layingCards.length) {
            placement.x /= layingCards.length;
            placement.y /= layingCards.length;
            placement.rot /= layingCards.length;
        } else {
            placement = {x: 0, y: 0, rot: 0};
        }

        let scaleX = (1.13 + layingCards.length * 0.0015);
        let scaleY = (1 + (0.13 + layingCards.length * 0.0015) * Card.aspectRatio);
        shadow.scale.set(scaleX, scaleY, 1);
        shadow.rotation.z = placement.rot;
        shadow.position.x = placement.x;
        shadow.position.y = placement.y;

        if (layingCards.length) {
            shadow.material.opacity = 0.8 * Math.max((Math.PI / 3 - layingCards[0].realAngleRad) / (Math.PI / 3), 0);
        }
    } else {
        shadow.visible = false;
    }
}

const renderPagingFrame = delta => {
    if(gsap.globalTimeline.getChildren(true, true, false).length > 0){
        return;
    }
    damp(pp, "y", pagingTarget * 1000, 0.1, delta);

    if(dragObject && dragDelta){            // TODO continue animating also after dragging (keep dragObject in memory)

        // POSITION
        let positionTarget = {
            x: placementDragTarget.x,
            y: placementDragTarget.y
        };
        const absPos = dragObject.getAbsolutePosition();
        let posLimit = get3DPositionFrom2D(
            get2DPositionFrom3D(selectionPanel.position, camera),
            absPos.z,
            camera
        );
        if(isPaging){
            positionTarget.y = Math.min(posLimit.y, positionTarget.y);
        }
        damp(dragObject.position, "x", placementDragTarget.x, CARD_MOVEMENT_DAMP, delta);
        damp(dragObject.position, "y", positionTarget.y, CARD_MOVEMENT_DAMP, delta);

        // SCALE
        let targetScale = PLACED_CARD_SCALE - 0.00015 * absPos.z;
        let distY = Math.max(0, posLimit.y - dragObject.position.y) / posLimit.y;
        let scale = targetScale + distY * (1 - targetScale);

        damp(dragObject.scale, "x", scale, CARD_MOVEMENT_DAMP, delta);
        damp(dragObject.scale, "y", scale, CARD_MOVEMENT_DAMP, delta);

        //if(isVerticallyDragging){
        selectionPanel.highlight(dragObject);
        //}
    }


    if(topCard){
        topCard.setRotationInDeck(pp.y / 1000, allCardsInDeck.length)
        if(dragObject && dragDelta && isVerticallyDragging){
            dragObject.faceUpwards();
        }
    }
}

const render = () => {
    fitSize();

    const delta = clock.getDelta();

    // PAGING

    switch(arrangement){
        case "paging":
            renderPagingFrame(delta);
            break;
        case "gallery":
            applyCameraConstraints()
            //renderGalleryFrame(delta)
            break;
    }

    updateDeckShadow();

    controls.update();
    FPSOptimizer.recordFrame(); // TODO introduce "pause" method
    renderer.render(scene, camera);
}
const clock = new THREE.Clock();
renderer.setAnimationLoop(() => render());

let arrangement = "paging";

let isViewOnly = false;

let isDragging = false;
let isPaging = false;
let isVerticallyDragging = false;
let dragObject = null;
let startDeckPos = 0;
let startCardPosition = null;
let dragDelta = null;

const calculateDraggedCardZ = card => {
    let aboveDeck = allCardsInDeck.length * SIZE_FACTOR;
    let aboveSelectionPanel = selectionPanel.getNextCardZ();
    return Math.max(card.position.z, aboveDeck, aboveSelectionPanel);
}
const onCardClick = (card, dragDelta) => {
    if(dragDelta.length() < 10){
        if(arrangement == "gallery"){
            if(isViewOnly) return;
            card.toggleSelected();
            backendService.updateActivity({
                selections: {0: allCardsInDeck.filter(c => c.isSelected).map(c => c.cardId)}
            })
        }
    }
}
const onPointerDown = (object, pointer) => {

    switch (arrangement){
        case "paging":
            if (object && object instanceof CardNative) {
                if(object.container !== "deck" && !object.isTopCard()){
                    console.log("not top");
                    dragObject = null;
                    return;
                }

                if(object.realAngleRad < Card.maxAngleDeltaRad){
                    dragObject = object;
                }
                isDragging = true;

                isPaging = object.container === "deck";
                if(!isPaging){
                    selectionPanel.removeCard(dragObject);

                    //dragObject.position.z = Math.max(dragObject.position.z, allCardsInDeck.length * SIZE_FACTOR)
                } else {
                }
                startDeckPos = pagingTarget;

                startCardPosition = {x: object.position.x, y: object.position.y, z: object.position.z};


            } else {
                dragObject = null;
            }

            break;
        case "gallery":
            break;
    }

}
const handlePaging = e => {
    if(!isDragging || !isPaging) return;


    dragDelta = e.delta;


    if(allCardsInDeck.length && e.delta){
        if(!isVerticallyDragging){
            pagingTarget = maath.clamp(startDeckPos + (e.delta.x * 0.0003 * (80 / allCardsInDeck.length)), 0, 1);
        }
    }
    if(!isViewOnly){
        if(e.dragDirection === "vertical"){     // TODO be less strict about this condition
            if(isVerticallyDragging){
                placementDragTarget.y = Math.max(-e.delta.y * Card.verticalDragVelocity, 0);
                placementDragTarget.x = e.delta.x * Card.verticalDragVelocity
            } else {
                if(dragObject && dragObject.realAngleRad < Card.maxAngleDeltaRad && e.delta.y < -Card.minVerticalDrag){
                    placementDragTarget.y = -e.delta.y * 6;
                    isVerticallyDragging = true;
                    if(allCardsInDeck.length > 1){
                        let extra = (1 - dragObject.index / (allCardsInDeck.length - 1)) * (Math.PI / 2 - Card.maxAngleDeltaRad) / allCardsInDeck.length;
                        pagingTarget = extra + Card.maxAngleDeltaRad * (allCardsInDeck.length - dragObject.index - 1) / (Math.PI + Card.maxAngleDeltaRad * (allCardsInDeck.length))
                    } else {
                        pagingTarget = 0;
                    }

                    dragObject.position.z = calculateDraggedCardZ(dragObject);

                }
            }
        }
    }

}
const handlePlacement = e => {
    if(!isDragging || isPaging) return;

    placementDragTarget.x = e.delta.x * Card.verticalDragVelocity + startCardPosition.x;
    placementDragTarget.y = -e.delta.y * Card.verticalDragVelocity + startCardPosition.y;

}
const onPointerMove = e => {
    switch (arrangement){
        case "paging":
            if(isDragging){
                dragDelta = e.delta;
                if(isPaging){
                    handlePaging(e);            // handling cards in deck
                } else {
                    handlePlacement(e);         // handling cards on table
                }
            }
        case "gallery":
            break;
    }

}
const onPointerUp = e => {
    switch (arrangement) {
        case "paging":
            if(dragObject){
                let activityUpdated = false;

                dragObject.faceNaturally();
                let isInDeck = dragObject.container === "deck";

                if(selectionPanel.getPanelForCard(dragObject)){
                    // TRY PUTTING CARD IN SELECTION PANEL
                    if(isInDeck){
                        console.log("removing from deck");
                        removeCardFromDeck(dragObject);
                    }
                    console.log("putting in selection panel");
                    activityUpdated = selectionPanel.addCard(dragObject);
                    console.log(activityUpdated);
                } else {
                    // OTHERWISE - RETURN CARD TO DECK
                    if(isInDeck){
                        dragObject.position.y = 0;
                        dragObject.position.x = 0;
                        dragObject.position.z = dragObject.index * SIZE_FACTOR;
                        dragObject.scale.set(1, 1, 1);
                    } else {
                        putCardInDeck(dragObject);
                        pagingTarget = 0;
                        activityUpdated = true;
                    }
                }

                if(activityUpdated){
                    backendService.updateActivity({
                        selections: Object.assign({}, selectionPanel.getContent()),
                    })
                }
            }
            isDragging = false;
            isPaging = false;
            isVerticallyDragging = false;
            dragObject = null;
            dragDelta = null;
            placementDragTarget = {x: 0, y: 0};

            selectionPanel.cancelHighlight();

            break;
        case "gallery":
            break;
    }


}
const onTest = () => {
    setArrangement(arrangement === "paging" ? "gallery" : "paging");
}

const calculateCardPosition = (index) => {
    switch(arrangement){
        case "paging":
            return {
                x: 0,
                y: 0,
                z: index * SIZE_FACTOR
            }
        case "gallery":
            return {
                x: ((index % GALLERY_COLUMNS) - (GALLERY_COLUMNS - 1) / 2) * GALLERY_EFFECTIVE_CARD_WIDTH,
                //y: (((Math.floor(total / GALLERY_COLUMNS) - 1) / 2) - Math.floor(index / GALLERY_COLUMNS)) * Card.height * (1 + GALLERY_GAP_FACTOR),
                y: -(1 + Math.floor(index / GALLERY_COLUMNS)) * GALLERY_EFFECTIVE_CARD_HEIGHT,
                z: 0
            }
    }
}
const toPagingArrangement = immediate => {
    pagingTarget = 0;
    console.log("toPagingArrangement");
    console.log(isViewOnly);

    if(isViewOnly){
        selectionPanel.removeAllCards();
        selectionPanel.hide();
        setTimeout(() => {
            selectionPanel.removeFromParent();
        }, ARRANGEMENT_TRANSITION_DURATION_SEC)
    } else {
        selectionPanel.show()
    }


    topCard = null;

    let prevCard = null;
    allCardsInDeck.map((c, i) => {

        c.prevCard = prevCard;
        c.index = i;
        if(prevCard){
            prevCard.nextCard = c;
        }
        c.nextCard = null;
        topCard = c;

        c.setSelected(false);
        let target = calculateCardPosition(i);

        setTimeout( () => {
            gsap.to(c.position, {
                x: target.x, y: target.y, z: target.z,
                ease: "power1.out",
                duration: ARRANGEMENT_TRANSITION_DURATION_SEC * 0.5,
                onComplete: () => {
                    c.updateFrontTexture();
                }
            })
            c.randomizePlacement();
            c.updateDeckPivots((allCardsInDeck.length / 2 - i) * SIZE_FACTOR);
        }, immediate ? 0 : i * 50);

        prevCard = c;
    });

    console.log(topCard);

    controls.enablePan = false;
    controls.touches.ONE = THREE.TOUCH.PAN;
    controls.touches.TWO = THREE.TOUCH.DOLLY_ROTATE;
    controls.zoomToCursor = false;

    let cameraPos = isViewOnly ? PAGING_DEFAULT_CAMERA_POS_VIEW_ONLY : PAGING_DEFAULT_CAMERA_POS;
    let cameraTarget = isViewOnly ? PAGING_DEFAULT_CAMERA_TARGET_VIEW_ONLY : PAGING_DEFAULT_CAMERA_TARGET;

    phCard.position.y = 0;
    phCard.scale.set(1, 1, 1);

    if(immediate){
        camera.position.copy(cameraPos);
        controls.target.copy(cameraTarget);
        canvas.style.pointerEvents = "auto"
        camera.far = PAGING_FRUSTUM_FAR;
    } else {
        gsap.to(camera.position, {x: cameraPos.x, y: cameraPos.y, z: cameraPos.z, duration: ARRANGEMENT_TRANSITION_DURATION_SEC});
        gsap.to(controls, {maxDistance: PAGING_MAX_CAMERA_DISTANCE, duration: ARRANGEMENT_TRANSITION_DURATION_SEC});
        gsap.to(controls.target, {x: cameraTarget.x, y: cameraTarget.y, z: cameraTarget.z, duration: ARRANGEMENT_TRANSITION_DURATION_SEC, onComplete: () => {
                console.log("done. arrangement = paging");
                canvas.style.pointerEvents = "auto";
                controls.enableDamping = true;
                camera.far = PAGING_FRUSTUM_FAR
            }});
    }

    shadow.visible = false;

}

const applyCameraConstraints = e => {
    let relativeDistance = (controls.getDistance() - controls.minDistance) / (controls.maxDistance - controls.minDistance);
    let maxX = (1 - relativeDistance) * GALLERY_EFFECTIVE_CARD_WIDTH;
    let minX = (relativeDistance - 1) * GALLERY_EFFECTIVE_CARD_WIDTH;

    let snapPos = maath.clamp(controls.target.x, minX, maxX);

    controls.target.x = snapPos;
    camera.position.x = snapPos;

    //let minY = get3DPositionFrom2D({x: 0, y: 10}, 0, camera).y;
    if(allCardsInDeck.length){

        const fovRadians = maath.degToRad(camera.fov);
        const tanFovHalf = Math.tan(fovRadians / 2);

        const firstCardPos = calculateCardPosition(0);
        const lastCardPos = calculateCardPosition(allCardsInDeck.length - 1);

        const deltaZ = camera.position.z - firstCardPos.z
        const maxY = firstCardPos.y + GALLERY_EFFECTIVE_CARD_HEIGHT / 2 - (deltaZ * tanFovHalf);

        const deltaZ2 = camera.position.z - lastCardPos.z
        const minY = lastCardPos.y + GALLERY_EFFECTIVE_CARD_HEIGHT / 2 - (deltaZ2 * tanFovHalf);

        let snapPosY = maath.clamp(controls.target.y, minY, maxY);
        controls.target.y = snapPosY;
        camera.position.y = snapPosY;
    } else {
        controls.target.y = -3 * GALLERY_EFFECTIVE_CARD_HEIGHT;
        camera.position.y = -3 * GALLERY_EFFECTIVE_CARD_HEIGHT;
    }
}

const toGalleryArrangement = immediate => {

    let removedCards = selectionPanel.removeAllCards();
    removedCards.map(c => c.container = "deck");
    allCardsInDeck.push(...removedCards);

    if(immediate){
        allCardsInDeck.map((c, i) => {
            let target = calculateCardPosition(i);
            c.setSelected(false);
            c.position.set(target.x, target.y, target.z);
            c.scale.set(1, 1, 1);
            c.setPreview();
            c.resetPlacement(true);
        });
    } else {
        allCardsInDeck.map((c, i) => {
            let target = calculateCardPosition(i);

            setTimeout( () => {
                gsap.to(c.position, {
                    x: target.x, y: target.y, z: target.z,
                    ease: "power1.in",
                    duration: ARRANGEMENT_TRANSITION_DURATION_SEC * 0.7})
                gsap.to(c.scale, {
                    x: 1, y: 1, z: 1,
                    ease: "power1.in",
                    duration: ARRANGEMENT_TRANSITION_DURATION_SEC * 0.7})
                c.setPreview();
                c.resetPlacement(true);
            }, i * 50);
        })
    }


    controls.enablePan = true;
    controls.enableZoom = true;
    controls.enableRotate = false;
    controls.touches.ONE = THREE.TOUCH.PAN;
    controls.enableDamping = true;
    controls.zoomToCursor = true;
    controls.maxDistance = GALLERY_MAX_CAMERA_DISTANCE;

    controls.mouseButtons = {
        LEFT: THREE.MOUSE.PAN,
        RIGHT: THREE.MOUSE.PAN,
        MIDDLE: THREE.MOUSE.DOLLY
    }

    camera.far = GALLERY_FRUSTUM_FAR;


    let cameraPos = new THREE.Vector3(0, -3 * GALLERY_EFFECTIVE_CARD_HEIGHT, GALLERY_MAX_CAMERA_DISTANCE);
    let cameraTarget = new THREE.Vector3(0, -3 * GALLERY_EFFECTIVE_CARD_HEIGHT, 0);
    phCard.position.y = cameraTarget.y;
    phCard.scale.set(2, 2, 1);

    if(immediate){
        camera.position.copy(cameraPos);
        controls.target.copy(cameraTarget);

        canvas.style.pointerEvents = "auto"
    } else {
        gsap.to(camera.position, {x: cameraPos.x, y: cameraPos.y, z: cameraPos.z,
            ease: "power1.inOut",
            duration: ARRANGEMENT_TRANSITION_DURATION_SEC * 1.2});

        gsap.to(controls.target, {x: cameraTarget.x, y: cameraTarget.y, z: cameraTarget.z,
            ease: "power1.inOut",
            duration: ARRANGEMENT_TRANSITION_DURATION_SEC * 1.2,
            onComplete: () => {
                console.log("done. arrangement = gallery")
                canvas.style.pointerEvents = "auto"
            }
        });
    }

    camera.updateProjectionMatrix();

    selectionPanel.hide()
}
const setArrangement = (arr, immediate = false, force = false) => {
    if(arrangement === arr && !force){
        return false;
    }

    arrangement = arr;
    canvas.style.pointerEvents = "none";      // TODO manage in a more "reactive" manner
    switch(arr){
        case "gallery":
            console.log(pagingTarget)
            gsap.to(pp, {y: 0, duration: Math.min(pagingTarget * 10, 1),
                onUpdate: () => {
                    if (topCard) {
                        topCard.setRotationInDeck(pp.y / 1000, allCardsInDeck.length);
                    }
                },
                onComplete: () => {
                    toGalleryArrangement(immediate)
                }
            })
            break;
        case "paging":
            toPagingArrangement(immediate);
            break;
    }
    return true;


}
const deleteCard = cardId => {
    let card = cardMap[cardId];
    delete cardMap[cardId];

    if(!card) return;
    selectionPanel.removeCard(card);
    removeCardFromDeck(card);

    card.removeFromParent();

    if(topCard){
        topCard.setRotationInDeck(pp.y / 1000, allCardsInDeck.length);
    }


    /* TODO
        1. remove from cardMap, allCardsInDeck and selectionPanel
        2. reorder cards in deck / gallery
        3. remove from selection panel, send backendService.Activity if change detected
     */
}
const addCard = (url, loRezUrl, id, autoPageToTop = true) => {
    if(cardMap[id]){
        console.log("card already exists");
        return;
    }
    let card = new CardNative(
        url,
        loRezUrl,
        id,
        allCardsInDeck.length * SIZE_FACTOR,
        topCard,
        allCardsInDeck.length
    )
    card.container = "deck";

    let cardPos = calculateCardPosition(allCardsInDeck.length);
    card.position.set(cardPos.x, cardPos.y, cardPos.z);

    cardMap[id] = card;
    allCardsInDeck.push(card)

    topCard = card;
    scene.add(card)

    card.onPointerDown = onPointerDown;
    card.onClick = onCardClick;

    if(mainEnv){
        card.updateEnv(mainEnv);
    }
    if(arrangement === "gallery"){
        card.setPreview();
        card.resetPlacement(true);
    }
    allCardsInDeck.map((c, i) => c.updateDeckPivots((allCardsInDeck.length / 2 - i) * SIZE_FACTOR));
    if(autoPageToTop){
        pagingTarget = 0;
    }

    if(phCard.parent === scene){
        scene.remove(phCard);
    }

    shadow.visible = true;

}
const putCardInDeck = card => {
    card.index = allCardsInDeck.length;
    card.position.set(0, 0, card.index * SIZE_FACTOR)
    card.container = "deck";
    card.randomizePlacement();
    allCardsInDeck.push(card);
    card.prevCard = topCard;
    if(topCard){
        topCard.nextCard = card;
        card.nextCard = null;
    }
    topCard = card;
    allCardsInDeck.map((c, i) => c.updateDeckPivots((allCardsInDeck.length / 2 - i) * SIZE_FACTOR));

}
const removeCardFromDeck = card => {

    card.removeFromLinkedList();

    removeElementFromArray(allCardsInDeck, card);

    topCard = allCardsInDeck.length ? allCardsInDeck[allCardsInDeck.length - 1] : null;
    allCardsInDeck.map((c, i) => {
        c.index = i;
        c.position.z = i * SIZE_FACTOR;
        c.updateDeckPivots((allCardsInDeck.length / 2 - i) * SIZE_FACTOR)
    });
}


const selectionPanel = new SelectionPanel(SELECTION_PANEL_PLACEMENTS, 10, [i18n.t("drag_here")])
selectionPanel.position.y = Card.height * 1.2;
selectionPanel.position.z = -SIZE_FACTOR;
scene.add(selectionPanel);


const updateGalleryActivity = ownActivity => {
    let selectedIds = new Set();
    if(ownActivity.selections[0]){
        ownActivity.selections[0].map(c => {
            selectedIds.add(c.id);
        })
    }
    allCardsInDeck.map(card => {
        card.setSelected(selectedIds.has(card.cardId));
    })

}
const updatePagingActivity = ownActivity => {

    let currentContent = selectionPanel.getContent();
    const slimActivity = ownActivity.selections.map(s => s == null ? [] : s?.map(card => card.id));
    const changeDetected = JSON.stringify(currentContent) != JSON.stringify(slimActivity)
    if(!changeDetected){
        console.log("no change detected");
        return;
    }

    onPointerUp();

    let removedCards = selectionPanel.removeAllCards();

    removedCards.map(c => putCardInDeck(c));

    ownActivity.selections.map((sel, i) => {
        if(!sel) return;

        sel.map(cardData => {
            let card = cardMap[cardData.id];
            if(!card){
                return;
            }
            removeCardFromDeck(card);
            let placementRes = selectionPanel.addCard(card, i);

            if(!placementRes){
                putCardInDeck(card);
            }
        })
    })
}
const updateActivity = ownActivity => {
    console.log("updateActivity");
    console.log(ownActivity);
    if(!ownActivity || !ownActivity.selections){
        console.log("no selections");
        return;
    }

    switch(arrangement){
        case "paging":
            updatePagingActivity(ownActivity);
            break;
        case "gallery":
            updateGalleryActivity(ownActivity);
            break;
    }


}

const PlayroomNative = forwardRef((props, ref) => {
    console.log("Playroom Init");
    const [textureCube] = useState(new CubeTextureLoader().load(PlayroomConstants.Card.envTextureURLs));
    const {t} = useTranslation();

    useEffect(() => {
        i18n.on('languageChanged', lang => {
            selectionPanel.updateLabels(t("drag_here"))
            phCardMat.map.dispose();
            phCardMat.map = createLabelCanvas(t("card_needed"), false)
            phCardMat.needsUpdate = true;
        });

        backendService.on("card_added", cardData => {
            addCard(cardData.url, cardData.previewUrl, cardData.id, cardData.sender === backendService.getPartyId());
        })

        backendService.on("card_deleted", cardId => {
            deleteCard(cardId);
        })

        backendService.on("go_offline", () => {
            console.log("GO OFFLINE")

            switch(arrangement){
                case "paging":
                    selectionPanel.hide();
                    selectionPanel.removeAllCards();
                    setTimeout(() => {
                        selectionPanel.removeFromParent();
                    }, ARRANGEMENT_TRANSITION_DURATION_SEC)
                    break;
                case "gallery":
                    // TODO set all cards to unselected
                    break;
            }


            isViewOnly = true;
        })

        backendService.on("playroom_state", playroomData => {
            console.log("PR_STATE")
            // CONFIG
            if(playroomData.currentSession.config){
                let config = backendService.getSessionConfig();
                console.log(config);
                if(!isViewOnly){
                    if(config.arrangement === "paging"){
                        selectionPanel.updateConfig(config)
                    }
                }
                setArrangement(config.arrangement, true, isViewOnly);
            }

            // CARDS
            if(playroomData.currentSession.cards){
                Object.values(playroomData.currentSession.cards)
                    .sort((a, b) => (a.created || 0) - (b.created || 0))        // sort by 'created' ascending
                    // eslint-disable-next-line array-callback-return
                    .map(card => {
                        addCard(card.url, card.previewUrl, card.id)
                     })
            }

            // ACTIVITY
            console.log("SET ACTIVITY:");

            let ownActivity = playroomData.currentSession.enrichedActivity[backendService.getPartyId()];
            updateActivity(ownActivity)

        });

        backendService.on("activity", activityData => {
            if(!activityData) return;
            if(!activityData[backendService.getPartyId()]) return;
            updateActivity(activityData[backendService.getPartyId()])
        });

        backendService.on("session_updated", sessionData => {
            if(!sessionData) return;
            console.log("SESSION_UPDATED");
            console.log(sessionData);
            if(!sessionData.reuseDeck){
                // TODO update cards in deck
            }
            if(!setArrangement(sessionData.arrangement)){
                if(sessionData.resetSession){
                    updateActivity({}); // make sure activity is reset even on the same arrangement
                }

            }

        })

    }, [])


    useEffect(() => {
        textureCube.mapping = THREE.CubeReflectionMapping;
        allCardsInDeck.map(card => card.updateEnv(textureCube));
        mainEnv = textureCube;
    }, [textureCube])


    useImperativeHandle(ref, () => ({
        addCard, onTest
    }));

    let context = useInteraction3D(canvas, scene, camera);  // TODO call only once, move to useEffect
    context.onPointerMove = onPointerMove;
    context.onPointerUp = onPointerUp;

    return <>
        <div id={"playroom"} ref={ref => ref && ref.appendChild(canvas)}>

        </div>
    </>
});

export default React.memo(PlayroomNative);