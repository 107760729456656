import React, {useCallback, useEffect, useState} from "react";
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';

import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LayersIcon from '@mui/icons-material/Layers';
import GridViewIcon from '@mui/icons-material/GridView';
import ListSubheader from '@mui/material/ListSubheader';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';
import Switch from '@mui/material/Switch';

import {useTranslation} from "react-i18next";
import {useDialogs} from '@toolpad/core/useDialogs';


import "../../scss/SettingsMenu.scss";
import backendService from "../BackendService";
import {useLoader} from "./GlobalLoader";
import {isEmpty, isNonEmpty} from "../GlobalUtils";

const activityIcons = {
    "paging": <LayersIcon />,
    "gallery": <GridViewIcon />
}
const SettingsMenu = ({config, onChangeArrangement, ...props}) => {

    const { t, i18n } = useTranslation();
    const { showLoader, hideLoader } = useLoader();
    const dialogs = useDialogs();


    const [addCardsPermission, setAddCardsPermission] = useState(true);
    const [uploadMultipleCardsPermission, setUploadMultipleCardsPermission] = useState(false);

    useEffect(() => {
        console.log(config.partyPermissions);
        setAddCardsPermission(config?.partyPermissions?.addCards)
        setUploadMultipleCardsPermission(config?.partyPermissions?.uploadMultiple)
    }, [config]);


    const toActivityType = async type => {
        if(config.arrangement === type) return;
        let activityMade = isNonEmpty(Object.values(backendService.getActivity()).filter(p => !!Object.values(p.selections).filter(s => s != null).length))
        const confirmed = await dialogs.confirm(activityMade ? t("confirm_session_reset") : t("confirm_activity_change"), {
            okText: t("confirm"),
            cancelText: t("cancel"),
            title: ""
        });
        if(!confirmed) return;
        if(onChangeArrangement){
            onChangeArrangement(type);
        }
    }

    const togglePermissions = useCallback(type => {
        let tempConfig = {
            addCards: addCardsPermission,
            uploadMultiple: uploadMultipleCardsPermission
        }

        switch(type) {
            case "addCards":
                setAddCardsPermission(!addCardsPermission);
                tempConfig.addCards = !tempConfig.addCards;
                break;
            case "uploadMultiple":
                setUploadMultipleCardsPermission(!uploadMultipleCardsPermission);
                tempConfig.uploadMultiple = !tempConfig.uploadMultiple;
                break;
        }

        backendService.updateSession({partyPermissions: {
            addCards: tempConfig.addCards,
            uploadMultiple: tempConfig.uploadMultiple
        }})

    }, [addCardsPermission, uploadMultipleCardsPermission]);

    return <Box className="settings_menu" sx={{ width: 280 }} role="presentation">

        <List key="list_activities">
            <ListSubheader component="div" id="nested-list-subheader">
                {t("activity_type")}
            </ListSubheader>
            {
                ["paging", "gallery"].map(key => (
                    <ListItem disablePadding key={`settings_item_${key}`}>
                        <ListItemButton
                            key={`listItem_${key}`}
                            selected={config && config.arrangement === key}
                            onClick={() => toActivityType(key)}
                        >
                            <ListItemText className="align" primary={t(key)} />
                            <ListItemIcon>
                                {activityIcons[key]}
                            </ListItemIcon>
                        </ListItemButton>
                    </ListItem>
                ))
            }
        </List>
        <Divider />
        <List key="list_permissions">
            <ListSubheader component="div" id="nested-list-subheader">
                {t("parties_can")}
            </ListSubheader>

            <ListItem>
                <Switch
                    edge="end"
                    onChange={() => togglePermissions('addCards')}
                    checked={addCardsPermission}
                    inputProps={{
                        'aria-labelledby': 'switch-list-label-wifi',
                    }}
                />
                <ListItemText className="align" primary={t("add_cards")} />
                <ListItemIcon>
                    <AddCircleIcon />
                </ListItemIcon>

            </ListItem>
            <Collapse in={true} timeout="auto" unmountOnExit>
                <List disablePadding>
                    <ListItem sx={{ pr: 4 }}>
                        <Switch
                            edge="end"
                            onChange={() => togglePermissions('uploadMultiple')}
                            checked={uploadMultipleCardsPermission}
                            inputProps={{
                                'aria-labelledby': 'switch-list-label-wifi',
                            }}
                            disabled={!addCardsPermission}
                        />
                        <ListItemText className="align" primary={t("upload_multiple")} disabled={!addCardsPermission} />
                        <ListItemIcon disabled={!addCardsPermission}>
                            <ControlPointDuplicateIcon />
                        </ListItemIcon>

                    </ListItem>
                </List>
            </Collapse>


        </List>
    </Box>
}
export default React.memo(SettingsMenu);
