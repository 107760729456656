import {createSequentialArray} from "../../../GlobalUtils";
import EventEmitter from "events";


export const PIXEL_RATIO_UPDATE = "pixel_ratio_update";

const MIN_FPS = 30;
const MAX_FPS = 50;
const MIN_PIXEL_RATIO = 1;
const UPDATE_INTERVAL_MS = 1000;
const BUFFER_LENGTH = 60;


const timeBuffer = createSequentialArray(BUFFER_LENGTH).map(n => 0);
let lastTime = new Date().getTime();

let bufferIndex = 0;

let effectivePixelRatio = window.devicePixelRatio;
class FrameRateOptimizer extends EventEmitter {

    #verbose = false;

    constructor() {
        super();
        setInterval(this.optimize, UPDATE_INTERVAL_MS);

    }
    optimize(){
        let avg = timeBuffer.reduce((sum, val) => {
            return sum + val;
        }, 0) / timeBuffer.length;
        let fps = 1000 / avg;


        let updated = false;
        if(fps < MIN_FPS && effectivePixelRatio > MIN_PIXEL_RATIO){
            effectivePixelRatio = Math.round((effectivePixelRatio - 0.1) * 10) / 10;
            updated = true;
        }
        if(fps > MAX_FPS && effectivePixelRatio < window.devicePixelRatio){
            effectivePixelRatio = Math.round((effectivePixelRatio + 0.1) * 10) / 10;
            updated = true;
        }
        if(updated){
            that.emit(PIXEL_RATIO_UPDATE, effectivePixelRatio);
            if(that.#verbose){
                console.log(`FPS: ${fps} => Pixel Ratio: ${effectivePixelRatio}`);
            }
        }

    }

    recordFrame() {
        let now = new Date().getTime();
        let elapsed = now - lastTime;
        timeBuffer[bufferIndex++] = elapsed;
        bufferIndex %= timeBuffer.length;
        lastTime = now;
    }
}



const frameRateOptimizer = new FrameRateOptimizer();
const that = frameRateOptimizer;
export default frameRateOptimizer;