import React, {useEffect, useState} from "react";
import {Box} from "@mui/material";
import {useTranslation} from "react-i18next";
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';


import "../../scss/MenuBar.scss";
import backendService from "../BackendService";

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const PartyMenuBar =  props => {

    const { t, i18n } = useTranslation();
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        setShowAlert(true);
    }, [])

    const hideAlert = () => {
        setShowAlert(false);
    }

    const closeAction = (
        <>

            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={hideAlert}
                style={{flex: "1 0 auto", justifyContent: "end"}}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </>
    );

    return <div id="menu_bar" className="emphasized">
        <Box className="dir" sx={{ display: 'flex', alignItems: 'center', backgroundColor: "white", justifyContent: 'space-between'  }}>
            <h1>
                לצפיה בלבד
                <InfoIcon style={{position: "relative",
                    top: "7px",
                    margin: "0 10px"}}
                          onClick={e => setShowAlert(true)}
                />
            </h1>

        </Box>
        <Snackbar
            open={showAlert}
            className="dir align"
            autoHideDuration={4000}
            TransitionComponent={Slide}
            onClose={hideAlert}
            message={t("playroom_offline_message")}
            action={closeAction}
        />
    </div>
}
export default React.memo(PartyMenuBar);
