import React, {useState, useEffect} from "react";
import {Box, Tab, Tabs} from "@mui/material";
import {withParams} from "../GlobalUtils";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import MenuIcon from '@mui/icons-material/Menu';
import TelegramIcon from '@mui/icons-material/Telegram';
import ApiIcon from '@mui/icons-material/Api';
import IconButton from '@mui/material/IconButton';
import {isMobile} from 'react-device-detect';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import copy from 'copy-to-clipboard';



import "../../scss/MenuBar.scss";

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const OwnerMenuBar = ({playroomId, ...props}) => {
    const [tabValue, setTabValue] = useState(0);
    const [showLink, setShowLink] = useState(false);
    const [msg, setMsg] = useState("fd");

    const location = useLocation();
    const navigate = withParams(useNavigate(), location);

    const { t, i18n } = useTranslation();

    const closeLink = e => {
        setShowLink(false)
    }
    const onShareClick = async e => {
        navigate(`/${playroomId}/share`);
        return;
        let fallback = false;
        let shareUrl = `${window.location.protocol}//${window.location.hostname}/${playroomId}`;
        if(isMobile && navigator.share !== undefined){
            try {
                await navigator.share({ url: shareUrl });
            } catch (e) {
                if(!e.message.includes("canceled")){
                    fallback = true;
                }
            }
        } else {
            fallback = true;
        }

        if(fallback){
            copy(shareUrl);
            setMsg("הקישור הועתק")
            setShowLink(true)
        }


        if(props.onShare){
            props.onShare();
        }
    }
    const onTestClick = e => {
        if(props.onTest){
            props.onTest();
        }
    }
    const onMenuClick = e => {
        if(props.onMenu){
            props.onMenu();
        }
    }
    useEffect(() => {
        if (location.pathname === `/${playroomId}/monitor`) {
            setTabValue(1);
        } else {
            setTabValue(0);
        }
    }, [location.pathname]);
    const handleTabChange = (e, newValue) => {
        setTabValue(newValue);
        if (newValue === 0) {
            navigate(`/${playroomId}`);
        } else if (newValue === 1) {
            navigate(`/${playroomId}/monitor`);
        }
    }

    const closeAction = (
        <>
            {/*<Button size="small" onClick={closeLink} style={{flex: "1 0 auto", justifyContent: "end", margin: "0 10px"}}>
                COPY
            </Button>*/}
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={closeLink}
                style={{flex: "1 0 auto", justifyContent: "end"}}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </>
    );

    return <div id="menu_bar">
        <Box className="dir" sx={{ display: 'flex', alignItems: 'center', backgroundColor: "white", justifyContent: 'space-between'  }}>

            <Tabs style={{flex: "1"}} className="dir" value={tabValue} onChange={handleTabChange} aria-label="basic tabs" selectionFollowsFocus>
                <Tab label={t("tab_play")} {...a11yProps(0)} />
                <Tab label={t("tab_monitor")} {...a11yProps(1)} />
            </Tabs>
            <Box sx={{ order: 2}}>
                <IconButton onClick={onShareClick} sx={{ order: 2, margin: "0 10px" }}>
                    <TelegramIcon sx={{transform: "scale(1.2)"}} />
                </IconButton>
                <IconButton onClick={onMenuClick} sx={{ order: 3, margin: "0 10px" }}>
                    <MenuIcon />
                </IconButton>
                {/*<IconButton onClick={onTestClick} sx={{ order: 4, margin: "0 10px" }}>
                    <ApiIcon />
                </IconButton>*/}
            </Box>

        </Box>
        <Snackbar
            open={showLink}
            className="dir"
            autoHideDuration={5000}
            TransitionComponent={Slide}
            onClose={closeLink}
            message={msg}
            action={closeAction}
        />
    </div>
}
export default React.memo(OwnerMenuBar);
