import React, {useEffect, useRef, useState} from 'react';
import CardMaker from "./js/components/CardMaker";
import {OFFLINE_PLAYROOM} from "./js/constants";
import PlayroomNative from "./js/components/playroom/PlayroomNative";
import {BrowserRouter as Router, Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import backendService from "./js/BackendService"
import {useCookies} from 'react-cookie';
import './js/i18n';
import "./css/App.css"
import WelcomeScreen from "./js/components/WelcomeScreen";
import ShareScreen from "./js/components/ShareScreen";
import {useTranslation} from "react-i18next";
import {getPartyCookieName, withParams} from "./js/GlobalUtils";
import NewPlayroomScreen from "./js/components/NewPlayroomScreen";
import GlobalLoader, {useLoader} from "./js/components/GlobalLoader";
import ProtectedRoute from "./js/components/ProtectedRoute";
import OwnerMenuBar from "./js/components/OwnerMenuBar";
import SessionInfo from "./js/components/SessionInfo";
import {Beforeunload} from 'react-beforeunload';
import Drawer from '@mui/material/Drawer';


import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import IconButton from '@mui/material/IconButton';

import CloseIcon from '@mui/icons-material/Close';

import {DialogsProvider} from '@toolpad/core/useDialogs';
import SettingsMenu from "./js/components/SettingsMenu";
import PartyMenuBar from "./js/components/PartyMenuBar";


function App() {


    return (
        <Router>
            <GlobalLoader>
                <AppContent />
            </GlobalLoader>
        </Router>
    );
}

export const AppContext = React.createContext(null);
let backendServiceListenersAttached = false;

function AppContent() {

    const location = useLocation();
    const navigate = withParams(useNavigate(), location);
    const { showLoader, hideLoader } = useLoader();

    const [uiState, setUIState] = useState("playroom");
    const playroomRef = useRef();
    let playroomId = location.pathname.split("/")[1]
    const [isOwner, setIsOwner] = useState(false);
    const [cardMakerAvailable, setCardMakerAvailable] = useState(true);
    const [allowMultipleUploads, setAllowMultipleUploads] = useState(false);

    const [connected, setConnected] = useState(true);

    const [isViewOnly, setIsViewOnly] = useState(false);

    const { t, i18n } = useTranslation();

    const [cookies, setCookie] = useCookies(['token']);

    const params = new URLSearchParams(location.search);
    const [lang, setLang] = useState(params.get("lang") || "he");
    const [deckId, setDeckId] = useState(params.get("deckId") || "")

    const [alertVisible, setAlertVisible] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [menuVisible, setMenuVisible] = useState(false);

    const [sessionConfig, setSessionConfig] = useState({});

    let playroomInitialized = false;

    //useBeforeunload(e => e.preventDefault());

    const initPlayroom = () => {
        if(!playroomId) return;

        let partyId = "";
        if(playroomId == OFFLINE_PLAYROOM){
            partyId = "offline";        // TODO TEMP
        } else {
            setConnected(false);
        }

        if(!backendServiceListenersAttached){
            backendService.on("go_offline", data => {
                // TODO display alert only if not owner
                setIsViewOnly(true);
                //setAlertVisible(true);

                //setAlertMessage( t("playroom_offline_message"))
            });
            backendService.on("playroom_state", data => {
                setIsOwner(data.isOwner);
                playroomInitialized = true;

                let config = backendService.getSessionConfig();
                setSessionConfig(config);
                setCardMakerAvailable(config.partyPermissions.addCards);
                setAllowMultipleUploads(config?.partyPermissions?.uploadMultiple);

            })
            backendService.on("session_updated", config => {
                hideLoader();
                setSessionConfig(config);
                setCardMakerAvailable(config.partyPermissions.addCards);
                setAllowMultipleUploads(config?.partyPermissions?.uploadMultiple);

            });
            backendService.on("connected", () => {
                setConnected(true);
            })
            backendService.on("disconnected", () => {
                if(playroomInitialized){
                    setConnected(false);
                }

            })
            backendService.on("message", data => {

                switch(data.type){
                    case "error":
                        switch(data.status){
                            case 404:
                                setAlertMessage(data.message)
                                setAlertVisible(true);
                                break;
                            case 401:
                                const partyCookie = cookies[getPartyCookieName(playroomId)];
                                if(partyCookie){
                                    partyId = partyCookie.partyId;
                                    console.log(partyId);
                                }
                                if(partyId){
                                    backendService.init(playroomId, partyId, "", partyCookie.fullName, playroomId === OFFLINE_PLAYROOM, lang);
                                } else {
                                    navigate(`/${playroomId}/welcome`);
                                }
                                break;
                        }
                        break;
                }
            })

            backendServiceListenersAttached = true;
        }


        if(!cookies.token){     // TODO ask user how to connect: owner / party
            navigate(`/${playroomId}/welcome`);
        }

        if(partyId || cookies.token){
            // TODO check for admins user for offline activities!!!
            backendService.init(playroomId, partyId, cookies.token, "", playroomId === OFFLINE_PLAYROOM, lang);
        }

    }

    useEffect(() => {
        i18n.changeLanguage(lang)
    }, [lang])

    useEffect(() => {
        if(playroomId !== "offline") return;
        // TODO getDeck from server and load cards
        if(!deckId) return;
        /*for(let i = 0; i < 60; i++){
            playroomRef.current.addCard(`https://content.vicapro.com/decks/${deckId}/web/${i}.jpg`, generateFirestoreId())
        }*/
    }, [deckId])

    useEffect(() => {
        const originalAlert = window.alert;
        window.alert = (message) => {
            setAlertMessage(message);
            setAlertVisible(true);
        }
        initPlayroom();
        return () => {
            window.alert = originalAlert;
        }
    }, [])

    const onEnterPlayroom = (partyId, fullName) => {
        backendService.init(playroomId, partyId, "", fullName, playroomId === OFFLINE_PLAYROOM, lang);
        navigate(`/${playroomId}`);
    }


    useEffect(() => {
        const currentLanguage = i18n.language;
        document.body.classList.toggle('rtl', currentLanguage === 'he');
        document.body.classList.toggle('ltr', currentLanguage !== 'he');
    }, [i18n.language]);


    useEffect(() => {
        setUIState((location.pathname == "/cardMaker" || location.pathname == "/cardMaker/camera") ? "card_maker" : "playroom")
    }, [location])

    useEffect(() => {
        console.log(uiState);
    }, [uiState])

    const onPopupClose = () => {
        navigate(`/${playroomId}`);  // Navigate back to Playroom
    }

    const onChangeArrangement = type => {
        showLoader(t("switching_activity"));    // TODO apply immediately in local client
        hideMenu();
        backendService.newSession({arrangement: type, reuseDeck: true});
    }
    const hideMenu = () => {
        setMenuVisible(false);
    }
    const showMenu = () => {
        setMenuVisible(true);
    }
    const showCardMaker = () => {
        navigate(`/${playroomId}/cardMaker/camera`);  // Navigate to CardMaker
    }

    const onCardCreated = (url, blob) => {

        navigate(`/${playroomId}`);  // Navigate back to Playroom
        let generatedId = backendService.addCard(blob, {text: "Hi there"})


        if(playroomRef.current){
            playroomRef.current.addCard(url, url, generatedId);   // Add some appearance effect to the added card
        }
    }

    const onPlayroomCreated = prId => {
        playroomId = prId;
        initPlayroom();
        navigate(`/${playroomId}`, {replace: true})
    }

    const onTest = () => {
        showLoader("מאתחלים פעילות חדשה");
        backendService.newSession({arrangement: "gallery", reuseDeck: true});
        //playroomRef.current.onTest();

    }

    const onBeforeUnload = e => {
        if(connected || isViewOnly) return;
        e.preventDefault()
    }



    const closeAction = (
        <>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => setAlertVisible(false)}
                sx={{flex: "1 0 auto", justifyContent: "end"}}
                style={{flex: "1 0 auto", justifyContent: "end"}}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </>
    );

    return (
        <DialogsProvider className={"dir"}>
            <Beforeunload onBeforeunload={onBeforeUnload} />
            <AppContext.Provider value={{isOwner: isOwner, playroomId: playroomId}}>
            <div className="App" style={{ overflow: "hidden", height: "100vh", width: "100vw" }}>
                <div style={{display: "flex", flexDirection: "column", position: "absolute", height: "100vh", width: "100vw", overflow: "hidden"}}>
                    {isOwner && <OwnerMenuBar playroomId={playroomId} onMenu={showMenu} onTest={onTest}></OwnerMenuBar>}
                    {!isOwner && isViewOnly && <PartyMenuBar />}
                    <div style={{flex: "1", flexDirection: "column", position: "relative", overflow: "hidden"}}>
                        <PlayroomNative ref={playroomRef} />
                        <Routes>
                            <Route path="*" element={null} />
                            <Route path="/:playroomId/monitor/*" element={
                                <ProtectedRoute>
                                    <SessionInfo playroomId={playroomId} />
                                </ProtectedRoute>
                            } />
                        </Routes>
                    </div>
                </div>


                <Routes>
                    {['/:playroomId/'].map(path => <Route key={`route_${path}`} path={path} element={
                        cardMakerAvailable && !isViewOnly &&
                            <div className={"bottom_bar"}>
                                <div className={"add_card_button"} onClick={showCardMaker}></div>
                            </div>
                    } />)}

                    {!isOwner && !isViewOnly &&
                        <Route path="/:playroomId/welcome" element={
                            <WelcomeScreen playroomId={playroomId} onEnter={onEnterPlayroom}/>
                        } />
                    }


                    <Route path="/:playroomId/share" element={
                        <ProtectedRoute>
                            <ShareScreen playroomId={playroomId} />
                        </ProtectedRoute>
                    } />

                    <Route path="/" element={
                        <NewPlayroomScreen onPlayroomCreated={onPlayroomCreated}/>
                    } />
                    {!isViewOnly &&
                        <Route path="/:playroomId/cardMaker/*" element={
                            <>
                                <div className={"bg_cover"} />
                                <CardMaker allowMultiple={isOwner || allowMultipleUploads} onClose={onPopupClose} onCardCreated={onCardCreated}></CardMaker>
                            </>
                        } />
                    }

                </Routes>
            </div>
            </AppContext.Provider>
            <Snackbar
                open={alertVisible}
                className="dir"
                autoHideDuration={4000}
                TransitionComponent={Slide}
                onClose={e => setAlertVisible(false)}
                message={alertMessage}
                action={closeAction}
            />
            <Drawer open={menuVisible} onClose={hideMenu} >
                <SettingsMenu config={sessionConfig} onChangeArrangement={onChangeArrangement}/>
            </Drawer>
        </DialogsProvider>
    );
}

export default App;
