import React, {useState, useEffect, useRef} from "react";

import {useTranslation} from "react-i18next";
import {useCookies} from "react-cookie";
import {formatDateString, generateFirestoreId, getPartyCookieName, withParams} from "../GlobalUtils";
import TextField from '@mui/material/TextField';
import {MONTH} from "../constants";
import Countdown from 'react-countdown';
import Link from '@mui/material/Link';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import {useLocation, useNavigate} from 'react-router-dom';


import "../../css/WelcomeScreen.css"
import backendService from "../BackendService";

const COOKIE_EXP = MONTH;
const WelcomeScreen = ({playroomId, onEnter, ...props}) => {
    const { t, i18n } = useTranslation();
    const [fullName, setFullName] = useState("");
    const [playroomInfo, setPlayroomInfo] = useState({});
    const [playroomTitle, setPlayroomTitle] = useState(t("the_playroom"))
    const [hwExp, setHwExp] = useState(null);
    const [cookies, setCookie, removeCookie] = useCookies(['token']);

    const location = useLocation();
    const navigate = withParams(useNavigate(), location);


    const fullNameText = useRef();

    const [partyCookie, setPartyCookie] = useState(cookies[getPartyCookieName(playroomId)]);

    const resetParty = () => {
        //removeCookie(getPartyCookieName(playroomId));
        setPartyCookie(null);
        setFullName("");
        fullNameText.current.focus();

    }

    const updateInfo = async () => {

        try{
            let playroomInfo = await backendService.getOfflineDetails(playroomId);
            if(playroomInfo){
                setPlayroomInfo(playroomInfo)
                setPlayroomTitle(playroomInfo.title ? `"${playroomInfo.title}"` : t("the_playroom"))
                playroomInfo.hwExpiration && setHwExp(formatDateString(playroomInfo.hwExpiration));
                setFullName(partyCookie?.fullName || "");

            }
        } catch(err){
            alert(err.message);
        }

    }

    useEffect(() => {
        updateInfo();
    }, [])

    const handleKeyDown = e => {
        if(e.keyCode == 13 && fullName.trim().length > 1){
            enterPlayroom();
            // put the login here
        }
    }
    const enterPlayroom = e => {
        // TODO validate fullname

        const domainParts = window.location.hostname.split('.');
        const domain = domainParts.length > 1 ? `.${domainParts.slice(-2).join('.')}` : window.location.hostname;

        const partyId = partyCookie ? partyCookie.partyId : generateFirestoreId();

        if(!partyCookie) {
            setCookie(getPartyCookieName(playroomId), {partyId: partyId, fullName: fullName}, {
                path: '/', // זמין לכל הנתיבים
                expires: new Date(new Date().getTime() + COOKIE_EXP), // תפוגה לאחר חודש
                domain: domain, // קביעת הדומיין
            });
        }

        if(onEnter){
            onEnter(partyId, fullName)
        }
    }
    return (
        <div id="welcome" className="ui_screen">
            <div className="dir main">
                <div className="avatar">
                    <div className="outer"></div>
                    <div className="inner">
                        {playroomInfo.ownerInitials}
                    </div>

                    <div className="owner_name">
                        {playroomInfo.ownerFullName}
                    </div>
                </div>

                <h1 className="dir">{t('welcome', {title: playroomTitle})}</h1>
                {(playroomInfo?.hwExpiration !== undefined && playroomInfo?.hwExpiration > new Date().getTime()) ?
                    <div className="hw dir align">
                        <div className="hw_header">
                            <h4>{t("hw_title")}</h4>
                            <div className="counter align-opp" caption={t("to_complete_by")}>
                                <Countdown  date={playroomInfo.hwExpiration} />
                            </div>


                        </div>

                        <div className="dir instructions">{decodeURIComponent(playroomInfo.hwInstructions)}</div>
                    </div> :
                    <div style={{height: "50px"}}></div>

                }
                <TextField
                    className="dir fullname_text"
                    inputRef={fullNameText}
                    label={t("fullName")}
                    variant="standard"
                    value={fullName}
                    onChange={e => setFullName(e.target.value)}
                    onKeyDown={handleKeyDown}/>
                <br />
                <button disabled={fullName.trim().length < 2} className="text_button theme full" onClick={enterPlayroom}>{t("enter")}</button>
                {!!partyCookie &&
                    <Link className="not_me"
                    onClick={e => resetParty()}>
                        <DoDisturbIcon />
                        {t("not_me")}
                    </Link>
                }

                <div
                    className="as_admin"
                    onClick={e => navigate("/")}>
                    {t("enter_as_owner")}
                </div>
            </div>

        </div>
    )
}

export default React.memo(WelcomeScreen);
