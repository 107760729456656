import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import "../../scss/NewPlayroomScreen.scss"
import {useCookies} from "react-cookie";


import backendService from "../BackendService"
import {useLoader} from "./GlobalLoader";
import {useLocation} from "react-router-dom";
import Login from "./Login";
import {LOGOUT} from "../recommendedActions";


const NewPlayroomScreen = ({onPlayroomCreated, ...props}) => {
    const { t, i18n } = useTranslation();
    const [cookies, setCookie, removeCookie] = useCookies(['token', 'userData']);

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const [deckId, setDeckId] = useState(params.get("deckId") || "");
    const [settingsSummary, setSettingsSummary] = useState([]);

    const { showLoader, hideLoader } = useLoader();
    const [hasPermission, setHasPermission] = useState(false)

    useEffect(() => {
        setHasPermission(cookies.userData && cookies.userData.permissions?.includes("playroom.create"))

    }, [cookies.userData])

    useEffect( () => {
        async function updateDeck(){
            let deckName = t("none");
            if(deckId){
                try{
                    let deck = await backendService.getDeck(deckId);
                    deckName = deck.displayName;
                } catch(err){
                    alert(err.message);
                }
            }
            setSettingsSummary(t("playroom_settings_summary", {deckName: deckName}).split("\n"));
        }
        updateDeck();
    }, [i18n.language])

    const reConfig = async e => {
        console.log("reConfig")
    }

    const logout = e => {
        removeCookie("token");
        removeCookie("userData");
    }
    const newPlayroom = async e => {
        showLoader(t("playroom_loader"));
        let config = {};
        if(deckId){
            config.deckId = deckId;
            console.log(deckId);
        }
        try{
            let playroomId = await backendService.createPlayroom({config});
            if(playroomId){
                if(onPlayroomCreated){
                    onPlayroomCreated(playroomId)
                }
            }
        } catch(err){
            alert(err.message);
            if(err.recommendedAction === LOGOUT){
                logout();
            }
        }

        hideLoader();
    }
    return <div className="dir" id="no_playroom">

        <h1>{t('no_playroom')}</h1>
        {cookies.token ?
            <div>
                {
                    hasPermission ?
                    <>
                        <ul className="dir">
                            {settingsSummary.map(line => <li key={line}>{line}</li>)}
                        </ul>
                        <div id="bottom_bar">
                            {
                                cookies.userData && cookies.userData.permissions?.includes("playroom.create") ?
                                <button className="text_button default" onClick={newPlayroom}>{t("confirm_and_enter")}</button>
                                    :
                                    null
                            }
                        </div>
                    </> :
                    <div>
                        <p>{t("no_permission")}</p>
                        <p><a href="mailto:business@vicapro.com" target="_blank">business@vicapro.com</a></p>
                        <p><br />
                            <button className="text_button" onClick={logout}>{t("logout")}</button>
                        </p>

                    </div>

                }
            </div>
            :
            <Login prompt={t('new_playroom_login_prompt')}></Login>
        }

    </div>
}

export default React.memo(NewPlayroomScreen);

